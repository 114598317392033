import { CircularProgress } from '@material-ui/core';
import axios from 'axios';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { AiOutlineSearch } from 'react-icons/ai';
import { toast } from 'react-toastify';
import {
  InputCpfCnpj,
  InputMaskCelular,
  InputMaskCep,
  InputMaskTelefone,
  InputSelect,
  InputText,
} from '~/components/NovosInputs';
import { ICEP, Uf } from '~/components/Pessoa/types';
import Separator from '~/components/Separator';
import { useMediaQuery } from '~/hooks/useMediaQuery';
import { useFocusTabFinalizacao } from '~/pages/PdvOnline/hooks/useFocusTabFinalizacao';
import { usePdvOnline } from '~/pages/PdvOnline/hooks/usePdvOnline';
import { tabFinalizacaoServices } from '~/pages/PdvOnline/services/tab-finalizacao';
import { CadastroCliente, SelectType } from '~/pages/PdvOnline/types';
import api from '~/services/api';
import {
  pontuaCpfCnpj,
  validaCPF,
  validaEmail,
  validarCNPJ,
} from '~/utils/functions';
import { Modal } from '..';
import {
  BairroComplementoContainer,
  BairroComplementoContent,
  BasicContent,
  ButtonOk,
  ButtonOkContainer,
  ButtonSearchCep,
  ButtonSearchCepContainer,
  CepLogradouroContent,
  CidadeUfContent,
  ClienteContainer,
  ContatoContent,
  EnderecoContainer,
  LogradouroNumeroContent,
  TelefoneCelularContent,
  TipoPessoaContent,
} from './styles';
import { resetCadastroCliente } from './utils/resetFields';
import { tipoPessoa } from './utils/tipoPessoa';

export const ModalCadastroCliente: React.FC = () => {
  const { isModalOpen, onCloseModal, isPdvOpen, handlePessoa } = usePdvOnline();

  const { handleInputFocus } = useFocusTabFinalizacao();
  const mediaQuery1400 = useMediaQuery({ maxWidth: 1400 });

  const [estados, setEstados] = useState<SelectType[]>([]);
  const [buscandoCep, setBuscandoCep] = useState(false);

  const modalContentRef = useRef<HTMLFormElement | null>(null);
  const buttonOkRef = useRef<HTMLButtonElement | null>(null);

  const {
    register,
    control,
    setValue,
    watch,
    resetField,
    handleSubmit,
    reset,
    setError,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm();

  const watchCep = watch('num_cep');

  const modalOpen =
    isPdvOpen && isModalOpen.isOpen && isModalOpen.modal === 'cadastroCliente';

  const { cadastrarCliente } = isModalOpen.data ?? {};

  useEffect(() => {
    if (!cadastrarCliente) return;

    setValue('num_cpf_cnpj', cadastrarCliente.num_cpf_cnpj);

    if (cadastrarCliente.num_cpf_cnpj?.length === 18) {
      setValue('tipo_pessoa', tipoPessoa[1]);
      return;
    }

    if (cadastrarCliente.num_cpf_cnpj?.length === 14) {
      setValue('tipo_pessoa', tipoPessoa[0]);
    }
  }, [cadastrarCliente, setValue]);

  useEffect(() => {
    if (!modalOpen) return;

    (async () => {
      const { data } = await api.get('/uf');

      if (data.success) {
        const options = data.data.map((ufData: Uf) => ({
          label: ufData.des_sigla?.toUpperCase(),
          value: ufData.cod_uf_ibge,
        }));
        setEstados(options);
      }
    })();
  }, [modalOpen]);

  const handleCloseModal = useCallback(() => {
    onCloseModal();
    handleInputFocus('num_cpf_cnpj');
    reset(resetCadastroCliente);
  }, [handleInputFocus, onCloseModal, reset]);

  useEffect(() => {
    if (!modalOpen) return;

    const inputName: HTMLInputElement | null = document.querySelector(
      '[name="nome_pessoa"]',
    );

    if (inputName) inputName.focus();
  }, [modalOpen]);

  useEffect(() => {
    const handleInputTabKey = (ev: KeyboardEvent) => {
      if (modalOpen && ev.key === 'Tab' && buttonOkRef.current) {
        if (document.activeElement === buttonOkRef.current) {
          if (modalContentRef.current) {
            const inputName: HTMLInputElement | null =
              modalContentRef.current.querySelector('[name="nome_pessoa"]');

            if (inputName) {
              setTimeout(() => {
                inputName.focus();
              }, 0);
            }
          }
        }
      }
    };

    window.addEventListener('keydown', handleInputTabKey);
    return () => window.removeEventListener('keydown', handleInputTabKey);
  }, [modalOpen, buttonOkRef]);

  useEffect(() => {
    const handleCloseModalKey = (ev: KeyboardEvent) => {
      if (modalOpen && ev.key === 'Escape') {
        handleCloseModal();
      }
    };

    window.addEventListener('keydown', handleCloseModalKey);
    return () => window.removeEventListener('keydown', handleCloseModalKey);
  }, [handleCloseModal, modalOpen]);

  const searchCep = useCallback(async () => {
    if (!watchCep || !modalOpen) return;

    const clearCep = watchCep.replace(/[^\d]/g, '');

    setBuscandoCep(true);

    try {
      const { data }: ICEP = await axios.get(
        `https://brasilapi.com.br/api/cep/v2/${clearCep}`,
      );

      if (!data) return;

      const uf = estados.find((e) => e.label === data.state);

      setValue('des_logradouro', data.street ?? '');
      setValue('des_uf', uf ?? '');
      setValue('des_bairro', data.neighborhood ?? '');
      setValue('des_cidade', data.city ?? '');
    } catch (error) {
      resetField('des_logradouro');
      resetField('des_uf');
      resetField('des_bairro');
      resetField('des_cidade');
      toast.warning('CEP não encontrado');
    } finally {
      setBuscandoCep(false);
    }
  }, [estados, modalOpen, resetField, setValue, watchCep]);

  const onSubmit: SubmitHandler<FieldValues> = useCallback(
    async (data) => {
      const values = data as CadastroCliente & { num_cpf_cnpj?: string };

      if (!values || !cadastrarCliente) return;

      if (values.num_cpf_cnpj) {
        const isCPF = values.num_cpf_cnpj.length === 14;
        const isCNPJ = values.num_cpf_cnpj.length === 18;
        const isInvalidType =
          typeof values.tipo_pessoa !== 'string' &&
          typeof values.tipo_pessoa !== 'number';

        const objTipoPessoa = (values.tipo_pessoa as SelectType) || {
          value: 0,
          label: 'Física',
        };

        if (isCPF && isInvalidType && Number(objTipoPessoa.value) !== 0) {
          handleInvalidType('CPF');
          return;
        }
        if (isCNPJ) {
          const isInvalid = handleInvalidTypeForCNPJ();
          if (isInvalid) return;
        }
      } else {
        clearErrors('tipo_pessoa');
      }

      function handleInvalidType(type: string) {
        toast.warning(`Tipo da Pessoa inválida para o ${type}`);
        setError('tipo_pessoa', { type: 'required' });
      }

      function handleInvalidTypeForCNPJ() {
        const isInvalid =
          typeof values.tipo_pessoa === 'string' ||
          typeof values.tipo_pessoa === 'undefined' ||
          (typeof values.tipo_pessoa !== 'string' &&
            typeof values.tipo_pessoa !== 'number' &&
            values.tipo_pessoa !== null &&
            values.tipo_pessoa !== undefined &&
            values.tipo_pessoa.value === 0);

        if (isInvalid) {
          handleInvalidType('CNPJ');
        }
        return isInvalid;
      }

      const numCpfCnpj =
        cadastrarCliente.num_cpf_cnpj === '' ||
        cadastrarCliente.num_cpf_cnpj === undefined
          ? values.num_cpf_cnpj
          : cadastrarCliente.num_cpf_cnpj;

      const isCnpj = (numCpfCnpj ?? '').replace(/[_\-./]/g, '').length > 11;

      const currentPayload: CadastroCliente = {
        ...values,
        num_cnpj: isCnpj ? numCpfCnpj : undefined,
        num_cpf: isCnpj ? undefined : numCpfCnpj,
      };

      if (modalContentRef.current) {
        const inputElement: HTMLInputElement | null =
          modalContentRef.current.querySelector(`[name="num_cpf_cnpj"]`);

        if (inputElement) {
          inputElement.focus();
          if (inputElement.value) inputElement.select();
        }

        if (
          (isCnpj && !currentPayload?.num_cnpj) ||
          (!isCnpj && !currentPayload?.num_cpf)
        ) {
          setError('num_cpf_cnpj', { type: 'required' });
          return;
        }
        clearErrors('num_cpf_cnpj');

        if (isCnpj && currentPayload.num_cnpj) {
          const valid = validarCNPJ(currentPayload.num_cnpj);
          if (!valid) {
            setError('num_cpf_cnpj', { type: 'required' });
            return;
          }
          clearErrors('num_cpf_cnpj');
        } else if (!isCnpj && currentPayload.num_cpf) {
          const valid = validaCPF(currentPayload.num_cpf);
          if (!valid) {
            setError('num_cpf_cnpj', { type: 'required' });
            return;
          }
          clearErrors('num_cpf_cnpj');
        }
      }

      if (currentPayload.email) {
        const valid = validaEmail(currentPayload.email);
        if (!valid) {
          setError('email', { type: 'value' });
          return;
        }
        clearErrors('email');
      }

      const payload: CadastroCliente = {
        nome_pessoa: currentPayload.nome_pessoa || '',
        tipo_pessoa:
          typeof currentPayload.tipo_pessoa === 'string'
            ? tipoPessoa[0].value
            : currentPayload.tipo_pessoa,
        num_cpf: currentPayload.num_cpf,
        num_cnpj: currentPayload.num_cnpj,
        num_telefone: currentPayload.num_telefone,
        num_celular: currentPayload.num_celular,
        email: currentPayload.email,
        num_cep: currentPayload.num_cep,
        des_logradouro: currentPayload.des_logradouro,
        num_endereco: currentPayload.num_endereco,
        des_bairro: currentPayload.des_bairro,
        des_complemento: currentPayload.des_complemento,
        des_cidade: currentPayload.des_cidade,
        des_uf:
          typeof currentPayload?.des_uf === 'string'
            ? ''
            : currentPayload?.des_uf?.label,
      };

      const novoCliente = await tabFinalizacaoServices.insereCliente(payload);

      if (novoCliente.success) toast.success(novoCliente.message);
      else toast.warning(novoCliente.message);

      let cpfCnpj: string;

      if (currentPayload.num_cpf) cpfCnpj = currentPayload.num_cpf;
      else if (currentPayload.num_cnpj) cpfCnpj = currentPayload.num_cnpj;
      else cpfCnpj = '';

      onCloseModal();
      reset(resetCadastroCliente);
      handleInputFocus('des_tecla');
      handlePessoa({ num_cpf_cnpj: pontuaCpfCnpj(cpfCnpj) });
    },
    [
      cadastrarCliente,
      clearErrors,
      handleInputFocus,
      handlePessoa,
      onCloseModal,
      reset,
      setError,
    ],
  );

  return (
    <Modal
      isOpen={modalOpen}
      title="Cadastrar Cliente"
      onClose={handleCloseModal}
    >
      <ClienteContainer ref={modalContentRef} onSubmit={handleSubmit(onSubmit)}>
        <BasicContent>
          <InputText
            label="Nome"
            maxLength={100}
            placeholder="Informe o nome do cliente"
            name="nome_pessoa"
            toLowerCase={false}
            register={register}
            isError={!!errors.nome_pessoa}
            onInput={(ev: any) => {
              setValue('nome_pessoa', ev.target.value);
            }}
            style={{ width: '21.875rem' }}
            isDisabled={isSubmitting}
          />
          <TipoPessoaContent>
            <InputSelect
              label="Tipo Pessoa"
              name="tipo_pessoa"
              placeholder={tipoPessoa[0].label}
              register={register}
              isError={!!errors.tipo_pessoa}
              control={control}
              options={tipoPessoa}
              changeSelected={(selected) => {
                setValue('tipo_pessoa', selected);
              }}
              isDisabled={!!cadastrarCliente?.num_cpf_cnpj || isSubmitting}
            />
            <InputCpfCnpj
              label="CPF/CNPJ"
              name="num_cpf_cnpj"
              control={control}
              register={register}
              setValue={setValue}
              isError={!!errors.num_cpf_cnpj}
              isDisabled={!!cadastrarCliente?.num_cpf_cnpj || isSubmitting}
            />
          </TipoPessoaContent>
        </BasicContent>
        <Separator labelText="Contato" background="transparent" color="black" />
        <ContatoContent>
          <TelefoneCelularContent>
            <InputMaskTelefone
              label="Telefone"
              name="num_telefone"
              isError={!!errors.num_telefone}
              control={control}
            />
            <InputMaskCelular
              label="Celular/WhatsApp"
              name="num_celular"
              isError={!!errors.num_celular}
              control={control}
            />
          </TelefoneCelularContent>
          <InputText
            label="Email"
            placeholder="Informe o E-mail"
            maxLength={100}
            name="email"
            toLowerCase={false}
            register={register}
            isError={!!errors.email}
            onInput={(ev: any) => {
              setValue('email', ev.target.value);
            }}
            style={{ width: mediaQuery1400 ? '22.8125rem' : '18.75rem' }}
            isDisabled={isSubmitting}
          />
        </ContatoContent>
        <Separator
          labelText="Endereço"
          background="transparent"
          color="black"
        />
        <EnderecoContainer>
          <CepLogradouroContent>
            <ButtonSearchCepContainer>
              <InputMaskCep
                label="CEP"
                name="num_cep"
                isError={!!errors.num_cep}
                control={control}
              />
              <ButtonSearchCep
                aria-label="buscar cep"
                type="button"
                onClick={searchCep}
                disabled={buscandoCep || isSubmitting}
              >
                {!buscandoCep && <AiOutlineSearch size={20} color="#fff" />}
                {buscandoCep && (
                  <CircularProgress style={{ color: '#fff' }} size={15} />
                )}
              </ButtonSearchCep>
            </ButtonSearchCepContainer>
            <LogradouroNumeroContent>
              <InputText
                label="Logradouro"
                placeholder="Informe o Logradouro"
                maxLength={100}
                name="des_logradouro"
                toLowerCase={false}
                register={register}
                isError={!!errors.des_logradouro}
                onInput={(ev: any) => {
                  setValue('des_logradouro', ev.target.value);
                }}
                style={{ width: '16.875rem' }}
                disabled={buscandoCep || isSubmitting}
              />
              <InputText
                label="Número"
                placeholder="Informe o Número"
                min={0}
                maxLength={15}
                name="num_endereco"
                toLowerCase={false}
                register={register}
                isError={!!errors.num_endereco}
                onInput={(ev: any) => {
                  setValue('num_endereco', ev.target.value);
                }}
                isDisabled={isSubmitting}
              />
            </LogradouroNumeroContent>
          </CepLogradouroContent>
          <BairroComplementoContainer>
            <BairroComplementoContent>
              <InputText
                label="Bairro"
                placeholder="Informe o Bairro"
                maxLength={50}
                name="des_bairro"
                toLowerCase={false}
                register={register}
                isError={!!errors.des_bairro}
                onInput={(ev: any) => {
                  setValue('des_bairro', ev.target.value);
                }}
                disabled={buscandoCep || isSubmitting}
              />
              <InputText
                label="Complemento"
                placeholder="Informe o Complemento"
                maxLength={50}
                name="des_complemento"
                toLowerCase={false}
                register={register}
                isError={!!errors.des_complemento}
                onInput={(ev: any) => {
                  setValue('des_complemento', ev.target.value);
                }}
                isDisabled={isSubmitting}
              />
            </BairroComplementoContent>
            <CidadeUfContent>
              <InputText
                label="Cidade"
                placeholder="Informe a Cidade"
                maxLength={50}
                name="des_cidade"
                toLowerCase={false}
                register={register}
                isError={!!errors.des_cidade}
                onInput={(ev: any) => {
                  setValue('des_cidade', ev.target.value);
                }}
                disabled={buscandoCep || isSubmitting}
              />
              <InputSelect
                label="UF"
                maxLength={2}
                setValue={setValue}
                placeholder="Selecione"
                name="des_uf"
                register={register}
                isError={!!errors.des_uf}
                control={control}
                options={estados}
                changeSelected={(selected) => {
                  setValue('des_uf', selected);
                }}
                style={{ width: '8.125rem' }}
                disabled={buscandoCep || isSubmitting}
              />
            </CidadeUfContent>
          </BairroComplementoContainer>
        </EnderecoContainer>
        <ButtonOkContainer>
          <ButtonOk ref={buttonOkRef} type="submit" disabled={isSubmitting}>
            Ok
          </ButtonOk>
        </ButtonOkContainer>
      </ClienteContainer>
    </Modal>
  );
};
